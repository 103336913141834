<template>
  <b-container
    fluid
    class="mb-5"
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="9"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableVehicles.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="form-group">
            <label>
              <strong>Vehicle Type</strong>
            </label>
            <b-select
              v-model="tableVehicles.filter.vehicle_type"
              :options="filterVehicleType"
            />
          </div>
        </b-col>

        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onCreate"
          >
            Create Vehicle
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableVehicles.perPage"
        :current-page="tableVehicles.currentPage"
        :items="tableProvider"
        :fields="tableVehicles.fields"
        :sort-by.sync="tableVehicles.sortBy"
        :sort-desc.sync="tableVehicles.sortDesc"
        :sort-direction="tableVehicles.sortDirection"
        :filter="tableVehicles.filter"
        :filter-included-fields="tableVehicles.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableVehicles.currentPage * tableVehicles.perPage - tableVehicles.perPage + (data.index + 1) }}
        </template>
        <template #cell(active)="data">
          {{ data.item.active === 1 ? 'Yes' : 'No' }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="onEdit(row.item)"
          >
            Edit
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableVehicles.perPage"
              :options="tableVehicles.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableVehicles.currentPage"
            :total-rows="tableVehicles.totalRows"
            :per-page="tableVehicles.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-vehicle-form"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
      @ok="onConfirmAction"
    >
      <ValidationObserver ref="form">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="name"
            vid="name"
            rules="required|max:100"
          >
            <div class="form-group">
              <label for="name">
                <strong>Name</strong>
              </label>
              <b-input
                id="name"
                v-model="vehicle.name"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="license plate"
            vid="license_plate"
            rules="max:15"
          >
            <div class="form-group">
              <label for="license_plate">
                <strong>License Plate Number</strong>
              </label>
              <b-input
                id="license_plate"
                v-model="vehicle.license_plate"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter license plate"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="registration expiry"
            vid="registration_expiry"
          >
            <div class="form-group">
              <label for="registration_expiry">
                <strong>Registration Expiry Date</strong>
              </label>
              <b-input
                id="registration_expiry"
                v-model="vehicle.registration_expiry"
                type="date"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="vehicle type"
            vid="vehicle_type"
            rules="required"
          >
            <div class="form-group">
              <label for="vehicle_type">
                <strong>Vehicle Type</strong>
              </label>
              <b-form-select
                id="vehicle_type"
                v-model="vehicle.vehicle_type"
                :options="list.vehicleTypes"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select Vehicle Type --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <ValidationProvider
            #default="{ errors }"
            name="seat capacity"
            vid="seat_capacity"
            rules="required|numeric|max_value:500"
          >
            <div class="form-group">
              <label for="seat_capacity">
                <strong>Seat Capacity ( Person )</strong>
              </label>
              <b-input
                id="seat_capacity"
                v-model="vehicle.seat_capacity"
                type="number"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter seat capacity"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>

          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="vehicle.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              switch
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SLOVehicle, SSharedList } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'VSLOVehicles',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Vehicles'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        drivers: [],
        vehicleTypes: []
      },
      vehicle: {
        id: 0,
        active: 0,
        name: '',
        license_plate: '',
        registration_expiry: '',
        vehicle_type: null,
        seat_capacity: 0
      },
      tableVehicles: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          vehicle_type: 'All',
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          { key: 'action', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'name', sortable: true },
          { key: 'vehicle_type_name', sortable: true },
          { key: 'license_plate' },
          { key: 'registration_expiry', class: 'text-center' },
          { key: 'seat_capacity', class: 'text-center' },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Vehicle' : 'New Vehicle'
    },

    filterVehicleType () {
      return [{ value: 'All', text: 'All' }].concat(this.list.vehicleTypes)
    }
  },

  mounted () {
    core.index()
    this.getVehicleTypes()
    this.getDrivers()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_vehicle_type: filter.vehicle_type,
        filter_text: filter.search
      })

      return await SLOVehicle.get(filters).then(
        ({ data }) => {
          this.tableVehicles.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tableVehicles.totalRows = 0
        return []
      })
    },

    async getVehicleTypes () {
      await SSharedList.getVehicleTypes().then(({ data }) => {
        this.list.vehicleTypes = data.map(
          vehicleType => ({
            text: vehicleType.name,
            value: vehicleType.id
          })
        )
      }).catch(() => {
        this.list.vehicleTypes = []
      })
    },

    async getDrivers () {
      await SSharedList.getDrivers().then(
        ({ data }) => {
          this.list.drivers = data.map(
            driver => ({
              text: `${driver.last_name}, ${driver.first_name}`,
              value: driver.id
            })
          )
        }
      ).catch(() => {
        this.list.drivers = []
      })
    },

    onCreate () {
      this.state.editing = false
      this.vehicle.id = 0
      this.vehicle.active = 1
      this.vehicle.name = ''
      this.vehicle.license_plate = ''
      this.vehicle.registration_expiry = ''
      this.vehicle.vehicle_type = null
      this.vehicle.seat_capacity = 0
      this.$bvModal.show('modal-vehicle-form')
    },

    onEdit (item) {
      this.state.editing = true
      this.vehicle.id = item.id
      this.vehicle.active = item.active
      this.vehicle.name = item.name
      this.vehicle.license_plate = item.license_plate
      this.vehicle.registration_expiry = item.registration_expiry
      this.vehicle.vehicle_type = item.vehicle_type_id
      this.vehicle.seat_capacity = item.seat_capacity
      this.$bvModal.show('modal-vehicle-form')
    },

    async onConfirmAction (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.state.editing ? 'Update Vehicle?' : 'Create Vehicle?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPut()
                }

                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! Your inputs seem to be incorrect or incomplete, please check and try again.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      return new Promise(
        resolve => {
          SLOVehicle.post(this.vehicle).then(
            ({ data }) => {
              this.$bvModal.hide('modal-vehicle-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.table.refresh()
              })
            }
          ).catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
              }
            }
          }).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onPut () {
      return new Promise(
        resolve => {
          SLOVehicle.put(this.vehicle).then(
            ({ data }) => {
              this.$bvModal.hide('modal-vehicle-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                const row = _.find(this.$refs.table.localItems, {
                  id: this.vehicle.id
                })
                row.active = data.vehicle.active
                row.name = data.vehicle.name
                row.license_plate = data.vehicle.license_plate
                row.registration_expiry = data.vehicle.registration_expiry
                row.vehicle_type = data.vehicle.vehicle_type_id
                row.driver = data.vehicle.driver_id
                row.seat_capacity = data.vehicle.seat_capacity
                row.updated_at = data.vehicle.updated_at
              })
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.form.setErrors(error.message)
                }
              }
            }
          ).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
